import { useAuth } from '@/app/providers/auth-provider';
import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const UserRoute = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { user } = useAuth();

  if (user) {
    return children;
  }

  return <Navigate to="/" state={{ from: location }} />;
};
